<template>
        <div class="text-white w-96 rounded-md">
            <h1 class="text-2xl px-3 font-semibold">Добавление быстрых адресов</h1>
            <div class="flex justify-between items-center my-4 px-3">
                <label class="text-sm">Название</label>
                <input class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text" v-model="name">
            </div>
            <div class="flex justify-between items-center my-4 px-3">
                <label class="text-sm">Категория</label>
                <v-select
                class="w-[270px] text-black bg-white"
                :options="categories"
                :reduce="category => category.id"
                v-model="categoty_id"
                label="name"></v-select>
            </div>
            <div class="flex justify-between my-4 px-3">
                <input type="checkbox" class="mt-1.5 mr-2">
                <div>Показывать на карте вместо улицы и номера дома </div>
            </div> 
            <div class="flex justify-between items-center my-4 px-3">
                <label class="text-sm">Улица</label>
                <v-select
                    class="w-[270px] text-black bg-white"
                    :options="streets"
                    :reduce="street => street.id"
                    v-model="street_id"
                    @input="changeStreetId"
                    label="name"
                    @search="onSearch"
                />
            </div>
            <div class="flex justify-between items-center my-4 px-3">
                <label class="text-sm">Номер дома</label>
                <v-select
                class="w-[270px] text-black bg-white"
                :options="home_numbers"
                :reduce="number => number.id"
                v-model="number_id"
                label="name"></v-select>
            </div>
            <div class="flex">
                <div class=" justify-between items-center my-4 px-3">
                    <div class="text-sm">Широта</div>
                    <input class="border rounded w-[220px] text-sm text-black px-2 py-1 round h-[40px]"
                    v-model="lat" type="text">
                </div>
                <div class=" justify-between items-center my-4 px-3">
                    <div class="text-sm">Долгота</div>
                    <input class="border rounded w-[220px] text-sm text-black px-2 py-1 round h-[40px]" 
                    v-model="lng" type="text">
                </div>
            </div>
            <small class="flex px-3 text-base text-yellow-500" v-if="!outsidePolygon">Дом выходит из границ города</small>
            <div class="flex my-4 px-3">
                <div class="bg-white text-black rounded-sm mr-2 p-1 cursor-pointer" @click="showSynonyms">Синонимы ({{lines.length}})</div>
            </div>
            <div v-if="synonymModal">
                <h1 class="text-2xl px-3 font-semibold">Синонимы</h1>
                <div class="flex justify-between my-4 px-3" v-for="(line, index) in lines" :key="index">
                    <input class="w-80 text-black" type="text" v-model="line.name">
                    <div class="bg-white text-black rounded-sm mr-1 px-2 cursor-pointer"><i class="ri-delete-bin-line" @click="removeLine(index)"></i></div>
                </div>
                <div class="flex justify-end my-4 px-3">
                        <div class="bg-white text-black rounded-sm mr-1 py-1 px-3 cursor-pointer" @click="addLine">+</div>
                </div>
            </div>
            <div class="flex justify-between my-4 px-3">
                <div class="bg-white text-black rounded-sm mr-2 p-1 cursor-pointer" @click="$emit('update')">Отмена</div>
                <div class="rounded mx-2 p-1 cursor-pointer" 
                :class="isActive ? 'bg-white text-black' : 'bg-black/40 text-white'" @click="addFastAdress">Сохранить</div>
            </div>
            <div class="flex my-6 px-3" v-if="addAny">
                <div class="bg-white text-black rounded-sm p-1 cursor-pointer text-sm px-2 py-1" @click="$emit('addFast')">Добавить</div>
            </div>
        </div>
</template>

<script>
import axios from 'axios'
import { debounce } from "debounce";

export default {
    props: ['village_id', 'fastAddressCoor', 'addFastAddress', 'outsidePolygon', 'addAny'],
    data() {
        return {
            categories: [],
            home_numbers: [],
            streets: [],
            countSynoniems: 0,
            synonymModal: false,
            lines: [],
            name: '',
            categoty_id: null,
            street_id: null,
            number_id: null,
            lat: this.fastAddressCoor.lat,
            lng: this.fastAddressCoor.lng
        }
    },
    computed: {
        isActive() {
            if(this.name &&
               this.categoty_id &&
               this.lat &&
               this.lng) {
                return true
               }
            else {
                return false
            }
        }
    },
    watch:{
        fastAddressCoor() {
            if(this.fastAddressCoor.length !== 0){
                let num = this.fastAddressCoor.indexOf(',')
                let end = this.fastAddressCoor.indexOf(']')
                this.lat = this.fastAddressCoor.substr(1, num-1)
                this.lng = this.fastAddressCoor.substring(num+1, end)
            }
        },
        addFastAddress() {
            if(this.addFastAddress) {
                this.getData()
            }
        }
    },
    methods: {
        onSearch(search, loading) {
            if(search.length){
                loading(true);
                this.searchStreetWithName(search)
                loading(false);
            }
        },
        searchStreetWithName: debounce(function (search){
            axios.get('addresses/streets', {params: { filter_name: search }})
                .then(response => {
                    this.streets = response.data;
                })
                .catch(error => {
                    console.log(error);
                })
        }, 400),
        changeStreetId() {
            axios.get('addresses/fast/addresses/data', {params: {street_id: this.street_id}})
            .then(res => {
                this.categories = res.data.categories
                this.home_numbers = res.data.home_numbers
            }).catch(err =>{
                console.log(err)
            })
        },
        showSynonyms() {
            this.synonymModal = !this.synonymModal
        },
        addLine() {
            this.lines.push({name: ''})
        },
        removeLine(index) { 
            this.lines.splice(index, 1)
        },
        addFastAdress() {
            axios.post('addresses/fast/addresses', {
                name: this.name,
                home_number_id: this.number_id ? this.number_id : undefined ,
                category_id: this.categoty_id,
                village_id: this.village_id,
                street_id: this.street_id ? this.street_id : undefined,
                lat: this.lat,
                lng: this.lng,
                synonyms: this.lines.length !== 0 ? JSON.stringify(this.lines) : undefined
            })
            .then(res =>{
                this.$emit('fastAddressAdded', res.data.fast_address)
                this.clearData()
                this.$emit('removeFastMarker')
                this.$emit('addFast')
                // this.$emit('close')
                this.$toast.open({
                    message: res.data.message,
                    type: 'success',
                    position: 'top'
                });
            }).catch(err => {
                this.$toast.open({
                    message: JSON.stringify(err.response.data.errors),
                    type: 'error',
                    position: 'top'
                });
                console.log(err);
            })
        },
        getData() {
            axios.get('addresses/fast/addresses/data')
            .then(res => {
                this.categories = res.data.categories
                this.home_numbers = res.data.home_numbers
            }).catch(err =>{
                console.log(err)
            })
            axios.get('addresses/streets', {params: {village_id: this.village_id}})
            .then(res => {
                this.streets = res.data
            }).catch(err =>{
                console.log(err)
            })
        },
        clearData() {
            this.name = '',
            this.number_id = null,
            this.categoty_id = null,
            this.street_id = null,
            this.lat = '',
            this.lng = '',
            this.lines = []
        }
    },
}
</script>