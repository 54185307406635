<template><div></div></template>

<script>
import L from 'leaflet';
export default {
    props: ['map', 'options'],
    data() {
        return {
            polygons: [],
            popup: [],
            customIconCenter: [],
            polygon: [],
        }
    },
    methods: {
        show() {
            this.options.forEach((e) => {
                if(e.lat !== null && e.latlngs !== null) {
                    this.polygons = this.options.map(option => ({
                        id: option.id,
                        vertices: option.latlngs ? JSON.parse(option.latlngs).map(point => [point.lat, point.lng]) : [],
                        color: option.color,
                        editing: false,
                        center: option.lat ? [option.lat, option.lng] : [],
                        editLatLng: false,
                        name: option.name,
                        zone_id: option.zone_id
                    }));
                }
            })
            this.createPolygons();
        },
        createPolygons() {
            this.polygons.forEach(poly => {
                if(!poly.center.length == 0 && !poly.vertices.length == 0) {
                    const polygon = L.polygon(poly.vertices, {
                        color:  'black',
                        fillColor: poly.color,
                        fillOpacity:  0.6,
                    }).addTo(this.map);

                    polygon.on('mouseover', (e) => this.populCursor(e, poly.id))
                    polygon.on('mouseout', () => this.clearMarkerPopul())

                    this.polygon.push(polygon)
                }
            })
        },
        populCursor(e, id) {
            this.clearMarkerPopul()
            if(e) {
                this.polygons.forEach((populs) => {
                    if(id == populs.id) {
                        if(!populs.editing) {
                            this.createCenterMarker(id)
                            let popup = L.popup()
                                .setLatLng([e.latlng.lat, e.latlng.lng])
                                .setContent(`<p>Район: ${populs.id}<br />Название: ${populs.name}<br/>Зона: ${populs.zone_id}</p>`)
                                .openOn(this.map);
    
                            this.popup.push(popup)
                        }
                    }
                })
            }
        },
        createCenterMarker(id) {
            this.clearMarkerPopul()
            this.polygons.forEach((markerCenter) => {
                if(id == markerCenter.id) {
                    const customIconCenter = L.divIcon({
                        className: 'custom-icon-center',
                        html: '<div style="border: 2px solid black; border-radius: 50%; width: 13px; height: 13px;"><div style="border: 1px solid black; border-radius: 50%; width: 5px; height: 5px; margin: 2px; background-color: black;"></div></div>',
                        iconSize: [10, 10],
                        iconAnchor: [10, 10]
                    });

                    const iconCenter = L.marker(markerCenter.center, {
                        icon: customIconCenter,
                    }).addTo(this.map)

                    this.customIconCenter.push(iconCenter)
                }
            })
        },
        clearMarkerPopul() {
            if(this.popup) this.popup.forEach(icon => icon.remove()); this.popup = [];
            if(this.customIconCenter) this.customIconCenter.forEach(icon => icon.remove()); this.customIconCenter = [];
        },
        clearPolygon() {
            if(this.polygon) this.polygon.forEach((poly) => poly.remove()); this.polygon = [];
        },
        mainClear() {
            this.clearPolygon()
        }
    }
}
</script>
