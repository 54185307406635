export default {
    data() {
        return {
            urls: [
                {
                    id: 'OpenStreetMap',
                    name: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png'
                },
                {
                    id: 'Google',
                    name: 'https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
                },
                {
                    id: 'Google Earth',
                    name: 'https://mt2.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'
                },
                {
                    id: 'Yandex',
                    name: 'https://core-renderer-tiles.maps.yandex.net/tiles?l=map&v=4.19.4&x={x}&y={y}&z={z}&scale=1&lang=ru_RU'
                },
                {
                    id: 'Yandex Hybrid',
                    name: 'https://core-sat.maps.yandex.net/tiles?l=sat&v=3.227.0.4&x={x}&y={y}&z={z}&scale=1&lang=ru_RU'
                }
            ],
            colors: [
                {
                id: 0,
                color: '#ff0000'
                },
                {
                id: 1,
                color: '#0A7B10'
                },
                {
                id: 2,
                color: '#fff000'
                },
                {
                id: 3,
                color: '#3374FF'
                },
                {
                id: 4,
                color: '#FF9933'
                },
                {
                id: 5,
                color: '#9C33FF'
                },
                {
                id: 6,
                color: '#4B0A7B'
                },
            ],
            polygon: [{
                "type": "Feature",
                "geometry": {
                  "type": "Polygon",
                  "coordinates": [
                    []
                  ]
                }
            }],
            district: [{
                "type": "Feature",
                "geometry": {
                  "type": "Polygon",
                  "coordinates": [
                    []
                  ]
                }
            }],
        }
    }
}