<template>
  <div style="display: none">
    <slot v-if="ready"></slot>
  </div>
</template>

<script>
import L from 'leaflet'
import { IRouter, IGeocoder, LineOptions } from 'leaflet-routing-machine'
import { findRealParent } from 'vue2-leaflet';

const props = {
  visible: {
    type: Boolean,
    default: false
  },
  waypoints: {
    type: Array,
    required: true
  },
  router: {
    type: IRouter
  },
  plan: {
    type: L.Routing.Plan
  },
  geocoder: {
    type: IGeocoder
  },
  fitSelectedRoutes: {
    type: [String, Boolean],
    default: 'smart'
  },
  lineOptions: {
    type: LineOptions
  },
  routeLine: {
    type: Function
  },
  autoRoute: {
    type: Boolean,
    default: true
  },
  addWaypoints: {
    type: Boolean,
    default: false
  },
  routeWhileDragging: {
    type: Boolean,
    default: false
  },
  routeDragInterval: {
    type: Number,
    default: 500
  },
  waypointMode: {
    type: String,
    default: 'connect'
  },
  useZoomParameter: {
    type: Boolean,
    default: false
  },
  showAlternatives: {
    type: Boolean,
    default: false
  },
  altLineOptions: {
    type: LineOptions
  },
  show: {
    type: Boolean,
    default: false
  }
}

export default {
  props,
  name: 'LRoutingMachine',
  data() {
    return {
      parentContainer: null,
      ready: false,
      layer: null,
      from_address: {
        from_street: '',
        home_number: '',
        latlngs: ''
      },
    }
  },
  mounted() {
    this.parentContainer = findRealParent(this.$parent)
    this.add()
    this.ready = true
  },
  beforeDestroy() {
    return this.layer ? this.layer.remove() : null
  },
  methods: {
    add () {
      console.log(this, 123213);
      if (this.parentContainer._isMounted) {
        const {
          waypoints,
          fitSelectedRoutes,
          autoRoute,
          addWaypoints,
          routeWhileDragging,
          routeDragInterval,
          waypointMode,
          useZoomParameter,
          showAlternatives,
          show,
          visible
        } = this

        const options = {
          waypoints,
          fitSelectedRoutes,
          autoRoute,
          addWaypoints,
          routeWhileDragging,
          routeDragInterval,
          waypointMode,
          useZoomParameter,
          showAlternatives,
          show,
          visible
        }

        const { mapObject } = this.parentContainer
        const routingLayer = L.Routing.control(options)
        
        routingLayer.addTo(mapObject)
        this.layer = routingLayer
      }
    },
    removeRoute() {
      if (this.layer) {
        this.layer.remove();
        this.layer = null;
      }
    }
  }
}
</script>
