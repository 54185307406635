<template>
    <div>
        <div class="text-white rounded-md text-sm">
            <h1 class="text-2xl px-3 font-semibold">Редактор улиц</h1>
            <div class="flex items-center mx-4 my-4">
                    <label for="1"></label>
                    <input type="radio" name="1" class="cursor-pointer"  value="create" v-model="edit" checked="checked">
                    <div class="ml-2">Создание</div>
                    <input type="radio" name="1" class="ml-4 cursor-pointer" value="edit" v-model="edit">
                    <div class="ml-2">Редактирование</div>
            </div>
            <div v-if="this.edit == 'create'">
                <div class="flex justify-between items-center my-4 px-3">
                    <label class="text-sm">Название</label>
                    <input class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text" v-model="street.name">
                </div>
            </div>
            <div v-else>
                <div class="flex justify-between items-center my-4 px-3">
                    <label class="text-sm">Улица</label>
                    <v-select
                    class="w-[270px] text-black bg-white"
                    :options="streets"
                    :reduce="street => street.id"
                    @input="editStreetData()"
                    v-model="editStreet.street_id"
                    label="name"></v-select>
                </div>
                <div class="flex justify-between items-center my-4 px-3">
                    <label class="text-sm">Переименовать</label>
                    <input class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text" v-model="editStreet.name">
                </div>
                <div class="flex justify-between items-center my-4 px-3">
                <label class="text-sm">Смена района</label>
                <!-- <select class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text" v-model="editStreet.district_id">
                    <option value=""></option>
                    <option :value="district.id" v-for="district in districts" :key="district.id">{{ district.name }}</option>
                </select> -->
                <v-select
                class="w-[270px] text-black bg-white"
                :options="districts"
                :reduce="district => district.id"
                v-model="editStreet.district_id"
                label="name"></v-select>
            </div>
            </div>
            <div class="flex justify-between items-center my-4 px-3">
                <label class="text-sm">Тип улицы</label>
                <!-- <select class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text" v-model="street.street_type_id">
                    <option value=""></option>
                    <option :value="typeStreet.id" v-for="typeStreet in typeStreets" :key="typeStreet.id">{{ typeStreet.name }}</option>
                </select> -->
                <v-select
                class="w-[270px] text-black bg-white"
                :options="typeStreets"
                :reduce="typeStreet => typeStreet.id"
                v-model="street.street_type_id"
                label="name"></v-select>
            </div>
            <button @click="showSynonyms" :class="this.edit == 'create' ?'bg-white text-black p-1 rounded-[2px] mx-3': 'bg-black text-gray-300 p-1 rounded-[2px] mx-3 cursor-default'">
                Синонимы ({{lines.length}})
            </button>
            <div class="flex justify-between items-center my-4 px-3">
                <label class="text-sm">Итоговый вид для клиентов</label>
                <input class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text">
            </div>
            <div v-if="synonymModal">
                <h1 class="text-2xl px-3 font-semibold">Синонимы</h1>
                <div class="flex justify-between my-4 px-3" v-for="(line, index) in lines" :key="index">
                    <input class="w-80 text-black" type="text" v-model="line.name">
                    <div class="bg-white text-black rounded-sm mr-1 px-2 cursor-pointer"><i class="ri-delete-bin-line" @click="removeLine(index)"></i></div>
                </div>
                <div class="flex justify-end my-4 px-3">
                        <div class="bg-white text-black rounded-sm mr-1 py-1 px-3 cursor-pointer" @click="addLine">+</div>
                </div>
            </div>
            <div class="flex justify-between my-6 px-3">
                <div class="bg-white text-black rounded-sm p-1 cursor-pointer text-sm px-2 py-1" @click="$emit('close')">Отмена</div>
                <div class="bg-white text-black rounded-sm mr-3 p-1 cursor-pointer text-sm ml-auto px-2 py-1" v-if="edit == 'edit'" @click="deleteStreet">Удалить</div>
                <div class="rounded-sm p-1 cursor-pointer text-sm px-2 py-1" :class="isActive ? 'bg-white text-black' : 'bg-black/40 text-white'" @click="postStreet">Сохранить</div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    props: ['dist_id_street', 'editStreets', 'village_id'],
    data() {
        return {
            edit: "create",
            typeStreets: [],
            streets: [],
            districts: [],
            editStreet: {
                street_id: null,
                name: '',
                district_id: null,
                street_type_id: null
            },
            synonymModal: false,
            lines: [],
            street: {
                name: '',
                street_type_id: null,
                district_id: undefined  
            }
        }
    },
    mounted() {
        this.getData()
    },
    computed: {
        isActive() {
            if(this.street.name &&
               this.street.street_type_id) {
                return true
               }
            else {
                return false
            }
        }
    },
    watch: {
        edit() {
            if(this.edit == 'edit') {
                axios.get('addresses/streets', {params: {village_id: this.village_id}})
                .then(res => {
                    console.log(res.data);
                    this.streets = res.data
                })
                .catch(err => {
                    console.log(err)
                })
            }
        },
        street_id(){
            if(this.editStreet.street_id){
               axios.get('addresses/streets', {params: {village_id: this.village_id}})
                .then(res => {
                    console.log(res.data);
                    this.streets = res.data
                })
                .catch(err => {
                    console.log(err)
                }) 
            }
        }
    },
    methods: {
        showSynonyms() {
            this.synonymModal = !this.synonymModal
        },
        addLine() {
            this.lines.push({name: ''})
        },
        removeLine(index) { 
            this.lines.splice(index, 1)
        },
        getData() {
            axios.get('addresses/street-types')
            .then(res => {
                console.log(res.data.street_types)
                this.typeStreets = res.data.street_types 
            })
            .catch(err => {
                console.log(err)
            })
            axios.get('addresses/districts')
            .then(res => {
                this.districts = res.data
            })
            .catch(err => {
                console.log(err)
            })
        },
        editStreetData(){
            let result = this.streets.filter(street => street.id == this.editStreet.street_id)
            this.editStreet.name = result[0].name;
            this.editStreet.street_type_id = result[0].street_type_id
            this.lines = result[0].synonyms
            console.log(result[0]);
        },
        deleteStreet() {
            if(this.edit == "edit") {
                axios.get(`addresses/streets/${this.editStreet.street_id}/edit`)
                .then(res => {
                    let street = res.data
                    axios.patch(`addresses/streets/${this.editStreet.street_id}`, {
                        street_id: this.editStreet.street_id,
                        name: street.name,
                        street_type_id: street.street_type_id,
                        village_id: this.village_id,
                        is_active: 0
                    })
                    .then(res => {
                        console.log(res)
                    })
                    .catch(err => {
                        console.log(err)
                    })
                })
                .catch(err => {
                    console.log(err);
                })
                
            }
        },
        clearData() {
            for (const [key] of Object.entries(this.street)) {
                this.street[key] = null
            }

            this.lines = []
            
            for (const [key] of Object.entries(this.editStreet)) {
                this.editStreet[key] = null
            }
        },
        postStreet() {
            if(this.edit == "create"){
                axios.post('addresses/streets',{
                    name: this.street.name,
                    street_type_id: this.street.street_type_id,
                    village_id: this.village_id,
                    synonyms: this.lines.length == 0 ? undefined : JSON.stringify(this.lines)
                })
                .then(res => {
                    console.log(res);
                    this.$emit('streetFalse', true, res.data.street_id)
                    this.$toast.open({
                        message: res.data.message,
                        type: 'success',
                        position: 'top'
                    });
                    this.$emit('sendStreetId', res.data.street_id)
                    this.$emit('close')
                    this.clearData()       
                }).catch(err => {
                    console.log(err);
                })
            }
            else {
                axios.patch(`addresses/streets/${this.editStreet.street_id}`, {
                    street_id: this.editStreet.street_id,
                    name: this.editStreet.name,
                    district_id: this.editStreet.district_id,
                    street_type_id: this.editStreet.street_type_id,
                    village_id: this.village_id,
                    synonyms: JSON.stringify(this.lines)
                })
                .then(res => {
                    console.log(res)
                    this.clearData()       
                })
                .catch(err => {
                    console.log(err)
                })
            }
        }
    },
}
</script>

<style>
.round{
    border-radius: 7px;
}
</style>