import api from "./axios"

export async function addHouse(payload) {
    try {
        return await api.post('/addresses/addresses', payload)
    } catch (error) {
        throw new Error(`API Request Error (addHouse): ${error}`)
    }
}

export async function getHomeTypes() {
    try {
        return await api.get('addresses/home-types')
    } catch (error) {
        throw new Error(`API Request Error (getHomeType): ${error}`)
    }
}

export async function getStreets(params) {
    try {
        return await api.get('addresses/streets', {params: params})
    } catch (error) {
        throw new Error(`API Request Error (getStreets): ${error}`)
    }
}

export async function getAllDistricts(params) {
    try {
        return await api.get('addresses/districts', {params: params})
    } catch (error) {
        throw new Error(`API Request Error (getAllDistricts): ${error}`)
    }
}