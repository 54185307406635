export default function calculateTriangleCentroid(coordinatesPolygon) {
    const n = coordinatesPolygon.length

    let A = 0;
    let C_x = 0;
    let C_y = 0;

    for (let i = 0; i < n; i++) {
        const x0 = coordinatesPolygon[i][0];
        const y0 = coordinatesPolygon[i][1];
        const x1 = coordinatesPolygon[(i + 1) % n][0];
        const y1 = coordinatesPolygon[(i + 1) % n][1];
        const a = x0 * y1 - x1 * y0;
        A += a;
        C_x += (x0 + x1) * a;
        C_y += (y0 + y1) * a;
    }

    A = A / 2;
    C_x = C_x / (6 * A);
    C_y = C_y / (6 * A);

    return [C_x, C_y];
    
}