<template>
    <div>
        <div class="text-white">
            <h1 class="text-2xl px-3 font-semibold">Редактировать район</h1>
            <div class="flex justify-between my-4 px-3">
                <label>Название</label>
                <input class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text" placeholder="Название" v-model="district.name">
            </div>
            <div class="flex justify-between my-4 px-3">
                <label>Зона</label>
                <v-select
                    class="w-[270px] text-black bg-white"
                    :options="zones"
                    :reduce="zone => zone.id"
                    v-model="district.zone_id"
                    label="name">
                </v-select>
            </div>
            <!-- <div class="flex my-4 px-3">
                <p class="mr-12">Площадь</p>
                <p>1.527 кв.км.</p>
            </div> -->
            <!-- <div class="flex my-4 px-3">
                <label class="mr-4">Наценки на<br> район</label>
                <div class="bg-white flex items-center text-black rounded-sm ml-4 p-1 cursor-pointer h-7">Редактировать</div>
            </div> -->
            <!-- <div class="flex my-4 px-3">
                <label class="mr-7">Соседние<br> районы</label>
                <div class="bg-white flex items-center text-black rounded-sm ml-4 p-1 cursor-pointer h-7">Редактировать</div>
            </div> -->
            <div class="flex justify-between my-4 px-3">
                <label>WKT</label>
                <input class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text" placeholder="WKT" v-model="district.polygon">
            </div>
            <!-- <div class="flex justify-between my-4 px-3">
                <label>Итоговый вид в <br> Taxsee Driver</label>
                <input class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text" placeholder="Итоговый вид в">
            </div> -->
            <div class="flex my-4 px-3">
                <div class="bg-white text-black rounded-sm mr-2 p-1 cursor-pointer" @click="$emit('update')">Отмена</div>
                <!-- <div class="bg-white text-black rounded-sm p-1 cursor-pointer ml-auto">Заблокировать</div> -->
                <div class="bg-black/40 text-white rounded ml-2 p-1 cursor-pointer" @click="updateDistrict()">Сохранить</div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    props: ['districtData','latlng'],
    data() {
        return {
            zones: [],
            district: {
                name: '',
                zone_id: '',
                polygon: '',
                lng: '',
                lat: '',
            }
        }
    },
    methods: {
       getZones() {
        axios.get('addresses/zones')
            .then(res => {
                this.zones = res.data
            })
        },
        updateDistrict( ) {
            this.$emit('postDistrict', this.district)
        }
    },
    watch: {
        latlng(newValue) {
            let data = JSON.stringify(newValue.polygon.map(point => ({
                lat: point[0],
                lng: point[1]
            })))
           this.district.polygon = data
           this.district.lat =  newValue.center.lat
           this.district.lng = newValue.center.lng
        },
        districtData(newValue) {
            this.district = newValue
            this.getZones()
        }
    }
}
</script>