<template>
    <div>
        <component :color="color" :is="type == 4 ? 'city-icon' : 'village-icon'"></component>
    </div>
</template>

<script>
import cityIcon from '@/components/icon/cityIcon.vue'
import villageIcon from '@/components/icon/villageIcon.vue'
    export default {
        props: ['color','type'],
        components: {
            cityIcon,
            villageIcon
        },
    }
</script>

<style lang="scss" scoped>

</style>