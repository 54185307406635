<template>
    <div v-if="editHouse">
        <div class="text-white rounded-md">
            <h1 class="text-2xl px-3 font-semibold">Редактировать дом</h1>
            <div class="flex justify-between items-center my-4 px-3">
                <label class="text-sm">Номер дома</label>
                <input v-if="editHouse.name" class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text"
                :value="editHouse.name" @change="changeData('name', $event.target.value)">
            </div>
            <div class="flex justify-between items-center my-4 px-3">
                <label class="text-sm">Тип дома</label>
                <v-select
                class="w-[270px] text-black bg-white"
                :options="home_types"
                :reduce="home => home.id"
                :value="editHouse.home_type_id"
                @input="changeData('home_type_id', $event)"
                label="name"></v-select>
            </div>  
            <div class="flex justify-between items-center my-4 px-3">
                <label class="text-sm">Улица</label>
                <v-select
                class="w-[270px] text-black bg-white"
                :options="streets"
                :reduce="street => street.id"
                :value="editHouse.street_id"
                @input="changeData('street_id', $event)"
                label="name"></v-select>
            </div>
            <div class="flex justify-between items-center my-4 px-3">
                <label class="text-sm">Район</label>
                <input class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text"
                :value="district_name" disabled>
            </div>
            <div class="flex justify-between items-center my-4 px-3">
                <label class="text-sm">Координаты</label>
                <input class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text" :value="coordinates">
            </div>   
            <div class="flex justify-between items-center my-4 px-3">
                <label class="text-sm">Привязка к дороге</label>
                <div class="bg-white text-black pl-6 pr-7 cursor-pointer text-sm px-2 py-1 rounded-[2px]">Редактировать</div>
            </div>
            <div class="bg-white text-black pl-6 pr-7 cursor-pointer text-sm px-2 py-1 rounded-[2px] w-36 ml-[120px]">Подъезды ({{count}})</div>
            <div class="flex my-6 px-3">
                <div class="bg-white text-black rounded-sm p-1 cursor-pointer text-sm px-2 py-1" @click="$emit('close')">Отмена</div>
                <div class="bg-white text-black rounded-sm mr-3 p-1 cursor-pointer text-sm ml-auto px-2 py-1" @click="deleteHome">Удалить</div>
                <button class="bg-white text-black rounded-sm p-1 cursor-pointer text-sm px-2 py-1" @click="editHome()" :disabled="editRequestLoading">Сохранить</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    props: ['editHouse', 'selectHome', 'village_id'],
    data() {
        return {
            count: 0,
            editRequestLoading: false,
            home_types: [],
            streets: [],
            district_name: null
        }
    },
    mounted() {
        if (this.selectHome) {
            axios.get('addresses/home-types')
            .then(res => {
                this.home_types = res.data.home_types
            }).catch(err => {
                console.log(err)
            })
            axios.get('addresses/streets')
            .then(res => {
               this.streets = res.data;
            })
            .catch(err => {
                console.log(err)
            })
        }
    },
    methods: {
        changeData(field, value){
            this.$emit('change', {field, value})
        },
        deleteHome() {
            this.editRequestLoading = true
            console.log(this.editHouse);
            axios.patch(`addresses/addresses/${this.editHouse.id}`, {
                address_id: this.editHouse.id,
                name: this.editHouse.name,
                street_id: this.editHouse.street_id,
                home_type_id: this.editHouse.home_type_id,
                lat: this.editHouse.lat,
                lng: this.editHouse.lng,
                is_active: 0
            })
            .then((res)=>{
                this.$emit('close')
                this.editRequestLoading = false
                this.$toast.open({
                    message: 'Адрес удален',
                    type: 'success',
                    position: 'top'
                });
                console.log(res);
                this.$emit('update')
            }).catch(err=>{
                this.editRequestLoading = false
                this.$toast.open({
                    message: err.response.data.errors,
                    type: 'error',
                    position: 'top'
                });
            }).finally(() => this.editRequestLoading = false)
        },
        editHome(){
            this.editRequestLoading = true
            console.log(this.editHouse);
            axios.patch(`addresses/addresses/${this.editHouse.id}`, {
                address_id: this.editHouse.id,
                name: this.editHouse.name,
                street_id: this.editHouse.street_id,
                home_type_id: this.editHouse.home_type_id,
                lat: this.editHouse.lat,
                lng: this.editHouse.lng,
            })
            .then((res)=>{
                this.$emit('close')
                this.editRequestLoading = false
                this.$toast.open({
                    message: res.data.message,
                    type: 'success',
                    position: 'top'
                });
                this.$emit('update')
            }).catch(err=>{
                this.editRequestLoading = false
                this.$toast.open({
                    message: err.response.data.errors,
                    type: 'error',
                    position: 'top'
                });
            }).finally(() => this.editRequestLoading = false)
        },
        getNotFoundStreetInSelect() {
            let street = this.streets.find(item => item.id === this.editHouse.street_id)
            if (!street) {
                axios.get(`addresses/streets/${this.editHouse.street_id}/edit`)
                .then(response => {
                    this.streets.push(response.data)
                })
                .catch(error => {
                    console.log(error)
                })
            }
        },
        getData() {
            axios.get('addresses/home-types')
            .then(res => {
                this.home_types = res.data.home_types
            }).catch(err => {
                console.log(err)
            })
            axios.get('addresses/streets', {params: {village_id: this.village_id}})
            .then(res => {
               this.streets = res.data
               this.getNotFoundStreetInSelect()
            })
            .catch(err => {
                console.log(err)
            })
        }
    },
    computed: {
        coordinates() {
            return JSON.stringify([this.editHouse.lat, this.editHouse.lng]) 
        }
    },
    watch: {
        selectHome() {
            this.close
            if (this.selectHome) {
                this.getData()
                axios.get(`addresses/districts/${this.editHouse.district_id}/edit`)
                .then(res => {
                    this.district_name = res.data.name
                })
                .catch(err => {
                    console.log(err)
                })
            }
        },
        editHouse: function(newVal, oldVal) {
            if(newVal !== oldVal){
                this.getNotFoundStreetInSelect();
            }
        },
        close() {
            this.district_name = null
        }
    },
}
</script>