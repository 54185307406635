import Vue from 'vue'
import Vuex from 'vuex'
import districtModule from './districtModule';

Vue.use(Vuex);

export const store = new Vuex.Store({
    
    state: {
        mapInstance: null,

    },
    getters: {
        isAuthenticate: state => !!state.token,
        authStatus: state => state.status,
    },
    actions: {
        updateMapInstance({ commit }, map) {
            commit('setMapInstance', map);
        },
    },
    mutations: {
        setMapInstance(state, map) {
            state.mapInstance = map;
        },
    },
    modules: {
        district: districtModule
    },
})


