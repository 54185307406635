<template>
<div class="text-white" >
    <h1 class="text-2xl px-3 font-semibold">Создание района</h1>
    <div class="my-4 px-3 flex justify-between">
        <label>Название <i style="color: red;">*</i></label>
        <input class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text" placeholder="Название" v-model="district.name">
    </div>
    <div class="my-4 px-3 flex justify-between">
        <label>Зона <i style="color: red;">*</i></label>
        <v-select
        class="w-[270px] text-black bg-white"
        :options="zones"
        :reduce="zone => zone.id"
        v-model="district.zone_id"
        label="name"></v-select>
    </div>
    <div class="my-4 px-3 flex justify-between">
        <p class="mr-12">Площадь</p>
        <p>{{ square }} кв.км.</p>
    </div>
    <div class="my-4 px-3 flex justify-between">
        <p>Население <i style="color: red;">*</i></p>
        <input class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="number"  placeholder="Население" v-model.number="district.population">
    </div>
    <div class="my-4 px-3 flex justify-between">
        <label>WKT <i style="color: red;">*</i></label>
        <input class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text" placeholder="WKT" v-model="district.polygon">
    </div>
    <!-- <div class="my-4 px-3 flex justify-between">
        <label>Итоговый вид в <br> Taxsee Driver</label>
        <input class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text" placeholder="Итоговый вид в">
    </div> -->
    <div class="flex justify-between my-4 px-3">
        <button class="bg-white text-black rounded-sm mr-2 p-1 cursor-pointer" @click="close()">Отмена</button>
        <button class="rounded ml-2 p-1 cursor-pointer" :disabled="isActive" :class="isActive ? 'bg-black/40 text-white' : ' bg-white text-black'" @click="postDistrict">Сохранить</button>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import L from 'leaflet';
// import { mapState, mapActions } from 'vuex';
export default {
    props: ['village_id','addDistrictsData'],
    data() {
        return {
            zones: [],
            district: {
                name: '',
                zone_id: null,
                village_id: null,
                polygon: null,
                population: null,
                lat: null,
                lng: null,
            },
            square: null,
            // createFormState: false
        }
    },
    mounted() {
        this.getZones()
    },
    watch: {
        addDistrictsData(newValue) {
            this.district.village_id = this.village_id
            this.calculatorSquare(newValue)
            this.district.polygon = JSON.stringify(newValue.polygon[0].coordinatesPolygon.map((e) => ({lat: e.coords[0][0], lng: e.coords[0][1]})))
            this.district.lat = newValue.center[0]._latlng.lat
            this.district.lng = newValue.center[0]._latlng.lng
        },
        // createFormState() {
        //     if(this.createFormState) {
        //         this.getZones()
        //     }
        // },
        // createDistrict(){
        //     if(this.createDistrict) {
        //         this.creationDistrict()
        //     }
        // }
    },
    computed: {
        isActive() {
            return this.district.name != '' ? false : true 
        },
        // ...mapState(['mapInstance']),
        // ...mapState('district', ['createDistrict', 'createFormState']),
    },
    methods: {
        // ...mapActions('district', ['sendCreationEvent', 'closeForm']),
        calculatorSquare(newValue) {
            let erea = L.GeometryUtil.geodesicArea(newValue.polygon[0].polygonObject.getLatLngs()[0])
            this.square = erea.toFixed(0)
        },  
        getZones() {
            axios.get('addresses/zones')
            .then(res => {
                this.zones = res.data
            })
        },
        postDistrict() {
            axios.post('addresses/districts', this.district)
            .then(res => {
                this.$toast.open({
                    type: 'success',
                    position: 'top',
                    message: res.data.message,
                });
                this.close();
            })
            .catch(err => {
                this.$toast.open({
                    type: 'success',
                    position: 'top',
                    message: err.data.message,
                });
            })
        },
        close(){
            this.closeForm()
            this.$emit('close')
        },
        closeForm() {
            this.zones = [],
            this.district = {
                name: '',
                zone_id: null,
                village_id: null,
                polygon: null,
                population: null,
                lat: null,
                lng: null,
            },
            this.square = null
        }
    }
}
</script>