<template>
    <div v-if="editFastHouse">
        <div class="text-white w-96 rounded-md">
            <h1 class="text-2xl px-3 font-semibold">Редактировать быстрый адрес</h1>
            <div class="flex justify-between items-center my-4 px-3">
                <label class="text-sm">Название</label>
                <input class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text"
                :value="editFastHouse.name" @change="changeData('name', $event.target.value)">
            </div>
            <div v-if="categories" class="flex justify-between items-center my-4 px-3">
                <label class="text-sm">Категория</label>
                <v-select
                class="w-[270px] text-black bg-white"
                :options="categories"
                :reduce="category => category.id"
                :value="editFastHouse.category_id"
                @input="changeData('category_id', $event)"
                label="name"></v-select>
            </div>
            <div class="flex justify-between my-4 px-3">
                <input type="checkbox" class="mt-1.5 mr-2">
                <div>Показывать на карте вместо улицы и номера дома </div>
            </div> 
            <div v-if="streets" class="flex justify-between items-center my-4 px-3">
                <label class="text-sm">Улица</label>
                <v-select
                class="w-[270px] text-black bg-white"
                :options="streets"
                :reduce="street => street.id"
                :value="editFastHouse.street_id"
                @input="changeData('street_id', $event)"
                label="name"></v-select>
            </div>
            <div v-if="home_numbers" class="flex justify-between items-center my-4 px-3">
                <label class="text-sm">Номер дома</label>
                <v-select
                class="w-[270px] text-black bg-white"
                :options="home_numbers"
                :reduce="number => number.id"
                :value="editFastHouse.home_number_id"
                @input="changeData('home_number_id', $event)"
                label="name"></v-select>
            </div>
            <div class="flex justify-between items-center my-4 px-3">
                <div class="text-sm">Координаты</div>
                <input class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text" :value="coordinates">
            </div>
            
            <div class="flex my-4 px-3">
                <div class="bg-white text-black rounded-sm mr-2 p-1 cursor-pointer">Места встреч (0)</div>
                <div class="bg-white text-black rounded-sm mr-2 p-1 cursor-pointer" @click="showSynonyms">Синонимы ({{lines.length}})</div>
            </div>
            <div v-if="synonymModal">
                <h1 class="text-2xl px-3 font-semibold">Синонимы</h1>
                <div class="flex justify-between my-4 px-3" v-for="(line, index) in lines" :key="index">
                    <input class="w-80 text-black" type="text" v-model="line.name">
                    <div class="bg-white text-black rounded-sm mr-1 px-2 cursor-pointer"><i class="ri-delete-bin-line" @click="removeLine(index)"></i></div>
                </div>
                <div class="flex justify-end my-4 px-3">
                    <div class="bg-white text-black rounded-sm mr-1 py-1 px-3 cursor-pointer" @click="addLine">+</div>
                </div>
            </div>
            <div class="flex my-4 px-3">
                <div
                @click="$emit('close')"
                class="bg-white text-black rounded-sm mr-2 p-1 cursor-pointer">Отмена</div>
                <div class="bg-white text-black rounded-sm p-1 cursor-pointer ml-auto" @click="deleteAdress">Заблокировать</div>
                <button @click="editAddress" :disabled="editRequestLoading" class="bg-black/40 text-white rounded mx-2 p-1 cursor-pointer">Сохранить</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    props: ['editFastHouse', 'editFastAddress', 'village_id'],
    data() {
        return {
            categories: null,
            editRequestLoading: false,
            home_numbers: null,
            streets: null,
            synonymModal: false,
            lines: [],
            name: '',
            lat: '',
            lng: '',
        }
    },
    methods: {
        changeData(field, value){
            this.$emit('change', {field, value})
        },
        showSynonyms() {
            this.synonymModal = !this.synonymModal
        },
        addLine() {
            this.lines.push({name: ''})
        },
        removeLine(index) { 
            this.lines.splice(index, 1)
        },
        deleteAdress() {
            this.editRequestLoading = true
            let data = {
                name: this.editFastHouse.name,
                category_id: this.editFastHouse.category_id,
                street_id: this.editFastHouse.street_id ? this.editFastHouse.street_id : undefined,
                lng: this.editFastHouse.lng,
                lat: this.editFastHouse.lat,
                village_id: this.village_id,
                is_active: 0
            }
            axios.patch(`addresses/fast/addresses/${this.editFastHouse.id}`, data).then((res)=>{
                this.$emit('close')
                this.editRequestLoading = false
                this.$toast.open({
                    message: res.data.message,
                    type: 'success',
                    position: 'top'
                });
                this.$emit('refresh')
            }).catch(err=>{
                this.editRequestLoading = false
                this.$toast.open({
                    message: err.response.data.errors,
                    type: 'error',
                    position: 'top'
                });
            }).finally(() => this.editRequestLoading = false)
        },
        editAddress() {
            this.editRequestLoading = true
            let data = {
                name: this.editFastHouse.name,
                category_id: this.editFastHouse.category_id,
                village_id: this.village_id,
                street_id: this.editFastHouse.street_id ? this.editFastHouse.street_id : undefined,
                lng: this.editFastHouse.lng,
                lat: this.editFastHouse.lat,
                synonyms: JSON.stringify(this.lines)
            }
            axios.patch(`addresses/fast/addresses/${this.editFastHouse.id}`, data).then((res)=>{
                this.$emit('close')
                this.editRequestLoading = false
                this.$toast.open({
                    message: res.data.message,
                    type: 'success',
                    position: 'top'
                });
            }).catch(err=>{
                this.editRequestLoading = false
                this.$toast.open({
                    message: err.response.data.errors,
                    type: 'error',
                    position: 'top'
                });
            }).finally(() => this.editRequestLoading = false)
        },
        getData() {
            axios.get('addresses/fast/addresses/data')
            .then(res => {
                this.categories = res.data.categories
                this.home_numbers = res.data.hom_numbers
            }).catch(err => console.log(err))
            axios.get('addresses/streets', {params:{village_id: this.village_id}})
            .then(res => {
               this.streets = res.data;
               this.getNotFoundStreetInSelect()
            })
            .catch(err => {
                console.log(err)
            })
        },
        getNotFoundStreetInSelect() {
            let street = this.streets.find(item => item.id === this.editFastHouse.street_id)
            if (!street) {
                axios.get(`addresses/streets/${this.editFastHouse.street_id}/edit`)
                .then(response => {
                    this.streets.push(response.data)
                })
                .catch(error => {
                    console.log(error)
                })
            }
        },
    },
    watch: {
        editFastHouse(value){
            this.getData()
            this.lines = value.synonyms
        }
    },
    computed: {
        coordinates() {
            return JSON.stringify([this.editFastHouse.lat, this.editFastHouse.lng]) 
        },
    }
}
</script>