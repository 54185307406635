<template>
    <div class="text-white">
        <div class="text-white">
            <h1 class="text-2xl px-3 font-semibold">Группировать в зоны</h1>
        </div>
        <div class="my-4 px-3 flex justify-between">
            <label class="mr-4">Выбранные районы</label>
            <div>{{zone.name ? zone.name : ''}}</div>
        </div>
        <div class="my-4 px-3 flex items-center justify-between ">
            <label>Зона</label>
            <v-select
            @input="getDistrictsInZone"
            class="w-[270px] text-black bg-white relative"
            :options="zones"
            :reduce="zone => zone.id"
            v-model="zone.zone_id"
            label="name"></v-select>
        </div>
        <!-- <div class="flex items-center my-6 px-3">
            <div class="bg-white text-black rounded-sm p-1 cursor-pointer text-sm px-2 py-1 ml-[105px]" @click="editorZone()">Редактор зон</div>
        </div> -->
        <div class="flex justify-between my-4 px-3">
            <div class="bg-white text-black rounded-sm mr-2 p-1 cursor-pointer" @click="$emit('update')">Отмена</div>
            <div class="rounded ml-2 p-1 cursor-pointer" :disabled="isActive" 
                :class="isActive ? 'bg-white text-black' : 'bg-black/40 text-white'" 
                @click="editZone()">Сохранить</div>
        </div>
        <!-- <div v-if="statusEditZone">
            <div class="text-white">
                <h1 class="text-2xl px-3 font-semibold">Редактор зон</h1>
            </div>
            <div class="flex items-center mx-4 my-4">
                    <label for="1"></label>
                    <input type="radio" name="1" class="cursor-pointer"  value="create" v-model="edit" checked="checked">
                    <div class="ml-2">Создание</div>
                    <input type="radio" name="1" class="ml-4 cursor-pointer" value="edit" v-model="edit">
                    <div class="ml-2">Редактирование</div>
            </div>
            <div class="my-4 px-3 flex items-center justify-between" v-if="edit === 'create'">
                <label>Название</label>
                <input class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text" placeholder="Название" v-model="name">
            </div>
            <div v-else-if="edit === 'edit'">
                <div class="my-4 px-3 flex items-center justify-between">
                    <label>Зона</label>
                    <v-select
                    class="w-[270px] text-black bg-white"
                    :options="zones"
                    :reduce="zone => zone.id"
                    v-model="zone.zone_id"
                    label="name"></v-select>
                </div>
                <div class="my-4 px-3 flex items-center justify-between">
                    <label>Название</label>
                    <input class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text" placeholder="Название" v-model="zone.name">
                </div>
            </div>
            <div class="flex justify-between my-4 px-3">
                <div class="bg-white text-black rounded-sm mr-2 p-1 cursor-pointer" @click="$emit('update')">Отмена</div>
                <div class="rounded ml-2 p-1 cursor-pointer" :disabled="isActive" 
                    :class="isActive ? 'bg-white text-black' : 'bg-black/40 text-white'" 
                    @click="addZone()">Сохранить</div>
            </div>
        </div> -->
    </div>
</template>

<script>
import axios from 'axios'
export default {
    props: ['village_id', 'addedDistrictsInZone', 'zona'],
    data() {
        return {
            zones: [],
            zone: {},
            statusEditZone: false,
            districtsIds: []
        }
    },
    mounted() {
        this.getZones()
    },
    computed: {
        isActive() {
            if(this.zone.zone_id &&
               this.zone.village_id) {
                return true
               }
            else {
                return false
            }
        }
    },
    watch: {
        zona(newValue) {
            axios.get(`addresses/districts/${newValue}/edit`)
            .then((res) => {
                this.zone = res.data
            })
            .catch((error) => {
                console.error(error);
            })
        },
        addedDistrictsInZone() {
            this.addedDistrictsInZone.forEach(element => {
                if(!this.districtsIds.includes(element.id)){
                    this.districtsIds.push(element.id)
                    this.districts = this.districts + `${element.name}, `
                }
            })
        }
    },
    methods: {
        editorZone() {
            this.statusEditZone = !this.statusEditZone 
        },
        getZones() {
            axios.get('addresses/zones')
            .then(res => {
                this.zones = res.data.reverse()
            })
        },
        getDistrictsInZone() {
            if(this.zone.zone_id) {
                axios.get(`addresses/zones/${this.zone.zone_id}/edit`)
                .then(res => {
                    this.zone.name = res.data.name 
                    this.zone.village_id = res.data.village_id
                    this.zone.districts = res.data.districts
                    this.$emit('getDistrictsInZones', res.data.districts)
                })
                .catch(err => {
                    console.log(err)
                })
            }
            else {
                this.districts = ''
            }
        },
        addZone() {
            let postData = {
                name: this.name,
                village_id: this.village_id
            }
            axios.post('addresses/zones', postData)
            .then(res => {
                console.log(res)
            })
            .catch(err => {
                console.log(err)
            })
        },
        editZone() {
            // let patchData = {
            //     zone_id: this.zone.zone_id,
            //     name: this.zone.name,
            //     village_id: this.zone.village_id,
            //     districts: this.districtsIds.length === 0 ? undefined : this.districtsIds 
            // }
            axios.patch(`addresses/zones/${this.zone.zone_id}`, this.zone)
            .then(res => {
                this.$toast.open({
                    message: res.data.message,
                    type: 'success',
                    position: 'top-right'
                });
                this.$emit('update')
            })
            .catch(err => {
                console.log(err)
            })
        }
    }
}
</script>