<template><div></div></template>
  
<script>
import L from 'leaflet';
export default {
props: ['map', 'options','value', 'fillOpacity',],
data() {
    return {
        name: 'PolygonMap',
        polygons: [],
        editingPolygonId: null,
        is_active: true,
        iconCenter: [],
        markers: [],
        popup: [],
        customIconCenter: null,
        center: null,
        centerMarkerEditPolygon: null,
    }
},
methods: {
    show() {
        if(this.is_active) {
            this.options.forEach((e) => {
                if(e.lat !== null && e.latlngs !== null) {
                    this.polygons = this.options.map(option => ({
                        id: option.id,
                        vertices: option.latlngs ? JSON.parse(option.latlngs).map(point => [point.lat, point.lng]) : [],
                        polygonObject: null,
                        editing: false,
                        color: option.color,
                        center: option.lat ? [option.lat, option.lng] : [],
                        editLatLng: false,
                        name: option.name,
                        zone_id: option.zone_id
                    }));
                }
            })
            this.initMap();
            this.is_active = false
        }
    },
    initMap() {
        this.createPolygons();
    },
    populCursor(e, id) {
        this.clearMarkerPopul()
        if(e) {
            this.polygons.forEach((poly) => {
                if(id == poly.id) {
                    if(!poly.editing) {
                        this.createCenterMarker(id)
                        let popup = L.popup()
                            .setLatLng([e.latlng.lat, e.latlng.lng])
                            .setContent(`<p>Район: ${poly.id}<br />Название: ${poly.name}<br/>Зона: ${poly.zone_id}</p>`)
                            .openOn(this.map);
            
                        this.popup.push(popup)
                    }
                }
            })
        }
    },
    clearMarkerPopul() {
        if(this.popup) this.popup.forEach(icon => icon.remove()); this.popup = [];
        if(this.iconCenter) this.iconCenter.forEach(icon => icon.remove()); this.iconCenter = [];
    },
    createCenterMarker(id) {
        this.clearMarkerPopul()
        this.polygons.forEach((poly) => {
        if(id == poly.id) {
                this.customIconCenter = L.divIcon({
                className: 'custom-marker',
                html: '<div style="border: 2px solid black; border-radius: 50%; width: 13px; height: 13px;"><div style="border: 1px solid black; border-radius: 50%; width: 5px; height: 5px; margin: 2px; background-color: black;"></div></div>',
                iconSize: [10, 10],
                iconAnchor: [10, 10]
            });
    
                const iconCenter = L.marker(poly.center, {
                icon: this.customIconCenter
                }).addTo(this.map)
    
                this.iconCenter.push(iconCenter);
            }
        })
    },    
    centerMarkerEdit(poly) {
        const centerMarkerEditPolygon = L.marker(poly.center, {
            icon: this.customIconCenter,
            draggable: true,
        }).addTo(this.map).on('dragend', (e) => {
            this.center = e.target._latlng
            this.polygons.map(p => {
                if(p.editing) this.$emit('input', {id: p.id, polygon: p.vertices, center: this.center ? this.center : p.center})
            })
        })

        this.centerMarkerEditPolygon = centerMarkerEditPolygon

    },
    createPolygons() {
    this.polygons.forEach(poly => {
        if(poly.center.length > 0 && poly.vertices.length > 0) {
            const polygon = L.polygon(poly.vertices, {
            color:  'black',
            fillColor: poly.color,
            fillOpacity:  0.6,
            }).addTo(this.map);
    
            polygon.on('click', () => this.enableEditing(poly.id));
            polygon.on('mouseover', (e) => this.populCursor(e, poly.id))
            polygon.on('mouseout', () => this.clearMarkerPopul())
            polygon.on('click', (e) => this.createMarker(e))
            poly.polygonObject = polygon;
            }
        });
    },
    clearPolygon() {
        this.polygons.forEach((e) => {
            if(e.polygonObject) {
                e.polygonObject.remove()
            }
        })
        this.editLatLng = false
    },
    enableEditing(polygonId) {
        if (this.editingPolygonId !== null && this.editingPolygonId !== polygonId) {
            if(polygonId !== this.editingPolygonId) {
                this.disableEditing();
                if(this.editLatLng) {
                    let status = confirm('Все несохранённые данные будут потеряны. Отменить изменения?')
                    if(status) {
                        this.clearPolygon()
                        this.is_active = true
                        this.show()
                        this.disableEditing();
                    }else {
                        return
                    }
                }
            }
        }

        const polygon = this.polygons.find(p => p.id === polygonId);

        if (!polygon) return;
        if (this.centerMarkerEditPolygon) this.centerMarkerEditPolygon.remove()
        this.centerMarkerEdit(polygon)

        this.editingPolygonId = polygonId;
        polygon.editing = true;

        this.$emit('open', polygonId)

        // this.createPolygons(polygonId, 'blue')

        const customIcon = L.divIcon({
            className: 'custom-marker',
            html: '<div style="background-color: #ffff; width: 10px; height: 10px; border-radius: 10%; border: 2px solid black;"></div>',
            iconSize: [10, 10],
            iconAnchor: [10, 10]
        });

        this.markers.forEach(marker => marker.remove());
        this.markers = [];

        polygon.vertices.forEach((coords, index) => {
            const marker = L.marker(coords, {
            draggable: true,
            icon: customIcon
            }).addTo(this.map).on('click', (e) => this.removeMarker(e, polygon.id, index));

            this.markers.push(marker);

            marker.on('drag', (e) => {
            const newPos = e.target.getLatLng();
            this.editLatLng = true
            polygon.vertices[index] = [newPos.lat, newPos.lng];
            polygon.polygonObject.setLatLngs(polygon.vertices);
            });

            marker.on('dragend', () => {
                this.polygons.map(p => {
                    if(p.editing) this.$emit('input', {id: p.id, polygon: p.vertices, center: this.center ? this.center : p.center})
                })
            })
        });
    },
    disableEditing() {
        if (this.editingPolygonId === null) return;

        const polygon = this.polygons.find(p => p.id === this.editingPolygonId);
        if (!polygon) return;
        
        polygon.editing = false;
        this.markers.forEach(marker => marker.remove());
        this.markers = [];
        this.editingPolygonId = null;
    },
    createMarker(e) {
        if (this.editingPolygonId === null) return;
        if(this.editLatLng) {
            const polygon = this.polygons.find(p => p.id === this.editingPolygonId);
            if (!polygon) return;
    
            polygon.vertices.push([e.latlng.lat, e.latlng.lng]);
                this.enableEditing(polygon.id);
        }
    },
    removeMarker(e, polygonId, vertexIndex) {
        if (this.editingPolygonId !== polygonId) return;

        const polygon = this.polygons.find(p => p.id === polygonId);
        if (!polygon) return;

        polygon.vertices.splice(vertexIndex, 1);
            this.enableEditing(polygon.id);
        }
    },
}
</script>

<style scoped>
.custom-marker {
background-color: #ffff;
width: 10px;
height: 10px;
border-radius: 10%;
border: 2px solid black;
}
</style>
  