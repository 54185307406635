<template>
    <div>
        <div class="text-white rounded-md">
            <h1 class="text-2xl px-3 font-semibold">Добавление домов, улиц</h1>
            <div class="flex justify-between items-center my-4 px-3">
                <label class="text-sm whitespace-nowrap">Тип дома</label>
                <v-select
                class="w-[270px] text-black bg-white"
                :options="homeTypes"
                :reduce="type => type.id"
                v-model="address.home_type_id"
                label="name"></v-select>
            </div>
            <div class="flex justify-between items-center my-4 px-3">
                <label class="text-sm">Улица</label>
                <v-select
                    class="w-[270px] text-black bg-white"
                    :loading="loading"
                    :options="streets"
                    :reduce="street => street.id"
                    v-model="address.street_id"
                    label="name"
                    @search="onSearch"
                />
            </div>
            <div class="border w-[120px] text-sm text-black px-2 py-1 rounded-[2px] bg-white  cursor-pointer ml-[120px]" @click="streetEdit()">
                Редактор улиц
            </div>
            <div class="flex items-center mx-4 my-4">
                <input type="radio" id=" " name="2" class="cursor-pointer" checked="checked" value="1" v-model="employee">
                <div for="2" class="ml-2">Одиночное</div>
                <input type="radio" name="2" v-model="employee" value="2" class="ml-4 cursor-pointer">
                <div class="ml-2">Массовое</div>
            </div>
            <div v-if="this.employee == 1">
                <div class="flex justify-between items-center my-4 px-3">
                    <label class="text-sm">Номер дома</label>
                    <input ref="input" @input="clearError" class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text" v-model="address.name">
                </div>
                <div class="flex justify-between items-center my-4 px-3">
                    <div class="text-sm">Координаты</div>
                    <input class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]" type="text" :value="coor">
                </div>
                <small class="flex px-3 text-base text-yellow-500" v-if="!outsidePolygon">Дом выходит из границ города</small>
            </div>
            <div v-else>
            <div class="flex justify-between items-center my-4 px-3">
                <label class="text-sm">№ первого</label>
                <input name="" id="" class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]">
            </div>
            <div class="flex justify-between items-center my-4 px-3">
                <label class="text-sm">№ последнего</label>
                <input name="" id="" class="border rounded w-[270px] text-sm text-black px-2 py-1 round h-[40px]">
            </div>
                <div class="flex items-center mx-4 my-4">
                        <input type="radio" id=" " name="3" class="cursor-pointer">
                        <div for="2" class="ml-2">Четные</div>
                        <input type="radio" name="3" class="ml-4 cursor-pointer">
                        <div class="ml-2">Нечетные</div>
                        <input type="radio" name="3" class="ml-4 cursor-pointer" checked="checked">
                        <div class="ml-2">Последовательно</div>
                </div>
            </div>
            <div class="flex justify-between my-6 px-3">
                <div class="bg-white text-black rounded-sm p-1 cursor-pointer text-sm px-2 py-1" @click="clearData">Очистить</div>
                <!-- <div class="bg-white text-black rounded-sm mr-3 p-1 cursor-pointer text-sm ml-auto px-2 py-1">Удалить</div> -->
                <div class="rounded-sm p-1 cursor-pointer text-sm px-2 py-1" :class="isActive ? 'bg-white text-black' : 'bg-black/40 text-white'" @click="postAddresses">Сохранить</div>
            </div>
            <div class="flex items-center my-6 px-3" v-if="error">
                <small class="text-red-600 mr-3 text-base">Такой дом существует</small>
                <div class="bg-white text-black rounded-sm p-1 cursor-pointer text-sm px-2 py-1" @click="clickToShow">Показать</div>
            </div>
            <div class="flex my-6 px-3" v-if="addAny">
                <div class="bg-white text-black rounded-sm p-1 cursor-pointer text-sm px-2 py-1" @click="$emit('addCircleMarker'), addAny = false">Добавить</div>
            </div>
        </div>
        <form-street v-if="street == true" @streetFalse="streetFalse" @sendStreetId="getStreetId" :village_id="village_id"></form-street>
    </div>
</template>

<script>
import axios from 'axios'
import FormStreet from './FormStreet.vue'
import { getHomeTypes, getStreets } from '@/api'
import { debounce } from "debounce";

export default {
    props: ['village_id', 'coor', 'addLoc', 'outsidePolygon'],
    components: {
        FormStreet
    },
    data(){
        return{
            employee: 1,
            street: false,
            streets: [],
            homeTypes: [],
            loading: false,
            address: {
                name: '',
                street_id: null,
                home_type_id: null,
                lat: '',
                lng: ''
            },
            addAny: false,
            error: false,
            errorAdress: null
        }
    },
    mounted() {
        this.getData()
    },
    computed: {
        isActive() {
            if(this.address.name &&
               this.address.street_id &&
               this.address.home_type_id &&
               this.address.lat &&
               this.address.lng){
                return true
               }
               else {
                return false
               }
        }
    },
    methods: {
        onSearch(search, loading) {
            if(search.length){
                loading(true);
                this.searchStreetWithName(search)
                loading(false);
            }
        },
        searchStreetWithName: debounce(function (search){
            axios.get('addresses/streets', {params: { filter_name: search, village_id: this.village_id }})
                .then(response => {
                    this.streets = response.data;
                })
                .catch(error => {
                    console.log(error);
                })
        }, 400),
        clearData() {
            this.address = {
                name: '',
                street_id: null,
                home_type_id: null,
                lat: '',
                lng: ''
            }
        },
        streetEdit(){
            this.street = !this.street
        },
        clearError() {
            this.error = false
        },
        streetFalse(status, street_id) {
            this.street = false
            this.address.street_id = street_id
            
            if(status) {
                this.getStreets()
            }
        },
        clickToShow(){
            this.$emit('centerToAdressError', this.errorAdress)
        },
        postAddresses() {
            if(this.isActive){ 
                axios.post('addresses/addresses', this.address)
                .then(res => {
                    this.loading = false
                    this.$toast.open({
                        message: res.data.message,
                        type: 'success',
                        position: 'top'
                    });
                    this.addAny = true
                    this.street = false
                    this.$emit('removeCircleMarker')
                    this.$emit('addCircleMarker')
                    this.$emit('addressAdded', res.data.address)
                })
                .catch(err => {
                    this.loading = false
                    if(err.response.data.errors.name == this.address.name) {
                        this.error = true
                        this.errorAdress = err.response.data.errors
                    }   
                })
            }
        },
        getData() {
            this.loading = true

            getHomeTypes() 
                .then(response => this.homeTypes = response.data.home_types)
                .catch(error => console.error(error))
                .finally(() => this.loading = false)
        },
        getStreets() {
            let params = {village_id: this.village_id}

            getStreets(params)
                .then(response => this.streets = response.data.reverse())
                .catch(error => console.err(error))
                .finally(() => this.loading = false)
        },
        getStreetId(id) {
            axios.get(`addresses/streets/${id}/edit`)
                .then(response => this.streets.unshift(response.data))
                .catch(error => console.err(error));
        },
        searchStreet(search) {
            
            console.log(search);
        }
    }, 
    watch:{
        coor() {
            if(this.coor.length !== 0){
                let num = this.coor.indexOf(',')
                let end = this.coor.indexOf(']')
                this.address.lat = this.coor.substr(1, num-1)
                this.address.lng = this.coor.substring(num+1, end)
            }
        },
        addLoc() {
            if(this.addLoc){
                axios.get('addresses/streets', {params: {village_id: this.village_id}})
                .then(res => {
                this.streets = res.data.reverse()
                this.loading = false
                })
                .catch(err => {
                    console.log(err)
                    this.loading = false
                })
            }
        }
    },
}
</script>

<style>
.round{
    border-radius: 7px;
}
input{
    height: 30px;
}
</style>