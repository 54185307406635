<template>
       <img :src="getImageSrc" alt="" srcset="">
</template>

<script>
    export default {
        props: {
            color: {
                type: String,
                default: 'green'
            }
        },
        computed: {
            getImageSrc() {
                return this.color === 'green' 
                    ? require('@/assets/img/editable-place.png')
                    : require('@/assets/img/visible-place-without-streets.png')
            }
        }
    }
</script>
