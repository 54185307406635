<template>
  <div>
    <div class="flex">
      <InputField
        placeholder="Введите адрес..."
        v-model="searchData"
        :addresses="addresses"
        @input="searchAdress"
        @focus="clearRoute"
        @select-address="changeData"
      />
      <button @click="searchAdress" class="text-center ml-2 h-[34px] cursor-pointer bg-white border-2 border-black rounded-md">
        <svg v-if="loading" class="animate-spin w-[30px] p-[5px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <img v-else class="w-[30px] p-[5px]" src="@/assets/img/magnifier.svg" alt="">
      </button>
      <button @click="changeRouteVisible" class="text-center h-[34px] ml-2 p-[5px] font-semibold bg-white border-2 border-black rounded-md">
        Маршрут
      </button>
    </div>
    <div v-if="showRoute" class="mt-9">
      <InputField
        placeholder="Откуда:" 
        v-model="fromPointRoute" 
        :addresses="firstPointList" 
        @select-address="setFirstPoint" 
        @input="searchFirstPoint" />
      <InputField 
        placeholder="Куда:" 
        v-model="toPointRoute" 
        :addresses="secondPointList"
        @select-address="setSecondPoint" 
        @input="searchSecondPoint" 
        class="mt-2" />
    </div>
  </div>
</template>

<script>
import InputField from '@/components/elements/InputField.vue';

export default {
  components: { InputField },
  props: ['value', 'searchAddress', 'isMarkerActive'],
  data() {
    return {
      searchData: null,
      showRoute: false,
      addresses: [],
      hasAddress: this.isMarkerActive,
      searchTimeout: null,
      loading: false,
      firstPointList: [],
      secondPointList: [],
      center: { lng: null, lat: null },
      fromPointRoute: '',
      toPointRoute: '',
      waypoints: [[null, null], [null, null]]
    };
  },
  methods: {
    async changeData(val) {
      if (val) {
        this.searchData = val ?  val.display_name : this.searchData
        this.hasAddress = true;
        this.center.lng = await val.lon;
        this.center.lat = await val.lat;
        this.$emit('handleChangeDataCenter', this.center, this.hasAddress);
      }
    },
    clearRoute() {
      this.showRoute = false
      this.waypoints = []
      this.fromPointRoute = '',
      this.toPointRoute = '',
      this.$emit('setWaypoints', this.waypoints)
      this.waypoints = [[null, null], [null, null]]
    },
    searchAdress() {
      if (!this.searchData) return;
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.loading = true;
        this.$http.get(`https://msearch.gram.tj/nominatim/search?q=${this.searchData}`)
          .then(res => {
            this.loading = false;
            this.addresses = res.data;
          }).catch(() => {
            this.loading = false;
          });
      }, 500);
    },
    searchFirstPoint() {
      if (!this.fromPointRoute) return;
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.$http.get(`https://msearch.gram.tj/nominatim/search?q=${this.fromPointRoute}`)
          .then(res => {
            this.firstPointList = res.data;
          }).catch((err) => {
            console.log(err);
          });
      }, 500);
    },
    searchSecondPoint() {
      if (!this.toPointRoute) return;
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.$http.get(`https://msearch.gram.tj/nominatim/search?q=${this.toPointRoute}`)
          .then(res => {
            this.secondPointList = res.data;
          }).catch((err) => {
            console.log(err);
          });
      }, 500);
    },
    async setFirstPoint(val) {
      
      this.fromPointRoute = val ? val.display_name : this.fromPointRoute
        this.waypoints[0][0] = await val ? val.lat : null
        this.waypoints[0][1] = await val ? val.lon : null
      this.$emit('setWaypoints', this.waypoints)
    },
    async setSecondPoint(val) {
      this.toPointRoute = val ? val.display_name : this.toPointRoute
      this.waypoints[1][0] = await val ? val.lat : null
      this.waypoints[1][1] = await val ? val.lon : null
      console.log(this.waypoints);
      this.$emit('setWaypoints', this.waypoints)
    },
    changeRouteVisible() {
      this.showRoute = !this.showRoute;
    }
  },
  watch: {
    searchData() {
      if (!this.searchData) {
        this.addresses.length = 0
        this.hasAddress = false;
        this.center = JSON.parse(localStorage.divisions)
        this.$emit('handleChangeDataCenter', this.center, this.hasAddress);
      }
    },
    fromPointRoute() {
      if (!this.fromPointRoute) {
        this.firstPointList.length = 0
      }
    },
    toPointRoute() {
      if (!this.toPointRoute) {
        this.secondPointList.length = 0
      }
    },
    searchAddress() {
      if (this.searchAddress) {
        this.addresses.push(this.searchAddress);
      }
    },
    isMarkerActive() {
      if (!this.isMarkerActive) {
        this.addresses = [];
        this.searchData = null;
      }
    }
  }
};
</script>

<style>
.leaflet-bar {
  display: none !important;
}
</style>
