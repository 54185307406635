import { getAllDistricts } from "@/api";

const districtModule = {
    namespaced: true,
    state: {
        createDistrict: false,
        createFormState: false,
        districts: []
    },
    getters: {
        createDistrict: state => state.createDistrict,
    },
    actions: {
        sendCreationEvent({ commit }, data) {
            commit('setCreateDistrict', data);
        },
        closeForm({ commit }) {
            commit('closeForm');
        },
        async getAllDistricts({ commit }, villageId) {
            let params = { village_id: villageId };
            await getAllDistricts(params).then(response => {
                let districts = response.data.map(district => {
                    return {
                        id: district.id,
                        name: district.name,
                        latlngs: district.polygon,
                        color: district.color,
                        zone_id: district.zone_id,
                        village_id: district.village_id,
                        opacity: 0.3,
                        lat: district.lat,
                        lng: district.lng
                    }
                })
                commit('setDistricts', districts);
            })
            .catch(error => { 
                console.error(error);
            })
        },
        makesTheArrayOfDistrictsEmpty({ commit }) {
            commit('setEmptyArrayToDistricts');
        }
    },
    mutations: {
        setCreateDistrict(state, data) {
            state.createDistrict = data;
            state.createFormState = data;
        },
        closeForm(state) {
            state.createDistrict = false;
            state.createFormState = false;
        },
        setDistricts(state, districts) {
            state.districts = districts;
        },
        setEmptyArrayToDistricts(state) {
            state.districts = [];
        }
    },
};

export default districtModule;