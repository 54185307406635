<template>
    <div class="relative">
      <input
        :placeholder="placeholder"
        @focus="onFocus"
        @input="onInput"
        v-model="internalValue"
        class="rounded-md h-[34px] text-base border-2 p-[10px] pr-[24px] border-black"
      >
      <div v-if="showClearButton" @click="clearInput" class="absolute left-[223px] top-[11px] cursor-pointer">
        <svg focusable="false" aria-hidden="true" width="12px" height="12px" fill="#000" viewBox="0 0 12 12"
          tabindex="0" role="button" aria-label="Delete pickup location" class="pe-location-clear css-ZSBbY">
          <path
            d="M10.595 0L6 4.596 1.405 0 0 1.404 4.596 6 0 10.595 1.405 12 6 7.404 10.595 12 12 10.595 7.404 6 12 1.404z">
          </path>
        </svg>
      </div>
      <div v-if="addresses.length > 0 && focused" class="bg-white overflow-y-scroll text-base border-2 border-black rounded-md max-h-[700px] h-full p-[10px] mt-[10px] max-w-[245px] w-full">
        <div @click="selectAddress(address)" class="border-b-[1px] cursor-pointer" v-for="address in addresses" :key="address.place_id">
          {{ address.display_name }}
        </div>
      </div>
    </div>
  </template>

  <script>
  export default {
    name: 'InputField',
    props: {
      placeholder: {
        type: String,
        default: ''
      },
      value: {
        type: String,
        default: ''
      },
      addresses: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        internalValue: this.value,
        showClearButton: !!this.value,
        focused: false
      };
    },
    watch: {
      value(newValue) {
        this.internalValue = newValue;
        this.showClearButton = !!newValue;
      }
    },
    methods: {
      onInput(event) {
        const value = event.target.value;
        this.$emit('input', value);
        this.showClearButton = !!value;
      },
      clearInput() {
        this.internalValue = '';
        this.$emit('input', '');
        this.showClearButton = false;
        this.selectAddress(null)
      },
      onFocus() {
        this.focused = true
        this.$emit('focus');
      },
      selectAddress(address) {
        this.focused = false
        this.internalValue = address ? address.display_name : null
        this.$emit('select-address', address);
      }
    }
  };
  </script>
  