import axios from "axios";

const api = axios.create({
    baseURL: 'https://api-3taxi.gram.tj/api/'
    // baseURL: 'https://test-admin.gram.tj/api/'
});

api.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('user-token');

api.interceptors.response.use(response => {
    return response
}, (error) => {
    if(error.request.status === 401) {
        localStorage.removeItem('user-token')
        window.location.reload()
    }
    return Promise.reject(error)
})

export default api;